import { easeInOutQuart, easeInOutSine } from "js-easing-functions";
import { Timeline, Animator } from "optimo-animator";
import { setCss } from "./utils";

enum Actions {
  Start = "start",
  Stop = "stop",
  Reset = "reset",
}

(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const delay = urlParams.get("delay") === "true";

  const image1 = document.querySelector(".image-1") as HTMLElement;
  const image2 = document.querySelector(".image-2") as HTMLElement;
  const text = document.querySelector(".text") as HTMLElement;

  const duration = 10000;

  const opacityTimeline = new Timeline([
    {
      duration: duration * 0.05,
      handler: () => {
        setCss(image1, {
          opacity: "0",
        });
      },
    },
    {
      duration: duration * 0.25,
      handler: (progress) => {
        const opacity = progress;
        setCss(image1, {
          opacity: opacity + "",
        });
      },
    },
    {
      duration: duration * 0.4,
      handler: () => {},
    },
    {
      duration: duration * 0.3,
      handler: (progress) => {
        const opacity = 1 - progress;
        setCss(image1, {
          opacity: opacity + "",
        });
      },
    },
  ]);

  const transformTimeline = new Timeline([
    {
      duration: duration,
      handler: (progress) => {
        const scaleFactor = 20;
        const p = progress;
        const scale1 = -0.5 + p * scaleFactor;
        const rotation = p * 180;

        setCss(image1, {
          transform: `translate(-55%, -48%) scale(${scale1}) rotate(${rotation}deg)`,
        });
      },
    },
  ]);

  const textTimeline = new Timeline([
    {
      duration: duration * 0.5,
      handler: (progress) => {
        const p = easeInOutQuart(progress, 0, 1, 1);
        const wght = 300 + p * 550;
        setCss(text, {
          fontVariationSettings: `"wght" ${wght}`,
        });
      },
    },
    {
      duration: duration * 0.5,
      handler: (progress) => {
        const p = 1 - easeInOutQuart(progress, 0, 1, 1);
        const wght = 300 + p * 550;
        setCss(text, {
          fontVariationSettings: `"wght" ${wght}`,
        });
      },
    },
  ]);

  const animator = new Animator([
    transformTimeline,
    textTimeline,
    opacityTimeline,
  ]);

  if (delay) {
    animator.stop();
    animator.startTime = 0;
    animator.computeFrameAt(0);
    window.addEventListener(
      "message",
      ({ data }) => {
        const { action } = data;
        if (action === Actions.Start) {
          animator.start();
        } else if (action === Actions.Stop) {
          animator.stop();
        } else if (action === Actions.Reset) {
          animator.startTime = 0;
          animator.computeFrameAt(0);
        }
      },
      false
    );
  } else {
    animator.start();
  }

  (window as any).animator = animator;
})();
